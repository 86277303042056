const roleType = {
  user: 1,
  manager: 2,
  admin: 3,
  companymanager: 4,
  branchmanager: 5,
  vehiclemanager: 6,
};

export default roleType;
