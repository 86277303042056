import { LOGIN, LOGOUT } from "../actions/types";

const initialState = { isLoggedIn: false, token: null };

export default function (state = initialState, action) {
  const { type, token } = action;

  switch (type) {
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        token: token,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        token: null,
      };
    default:
      return state;
  }
}
