import axios from "axios";
import authHeader from "./authHeader";
import roleType from "../containers/_roles";

const API_URL = "/api/User/";

class AuthService {
  login(mail, password) {
    return axios.post(API_URL + "Authenticate", { mail, password });
  }

  logout() {
    localStorage.clear();
  }

  register(firstName, lastName, mail, password, branchid) {
    let response = undefined;
    response = axios.post(API_URL + "Register/" + branchid, {
      firstName,
      lastName,
      mail,
      password,
    })
    .catch((error) => {
      console.log(error);
      
      if(error.response.data.errors){
        console.log("error.response.data" + JSON.stringify(error.response.data));
        return error.response.data;
      }else{
        var errorResponse = "{ \"errors\": \"Request failed with status code 400\"}"
        return JSON.parse(errorResponse);
      }
    });
    return response;
  }

  forgotpassword(mail, firstName="nofirstname", lastName="nolastname", password="nopassword") {
    let response = undefined;
    response = axios.put(API_URL + "ForgotPassword", {
      firstName,
      lastName,
      mail,
      password,
    })
    .catch((error) => {
      if(error.response.data.errors){
        return error.response.data;
      }else{
        var errorResponse = "{ \"errors\": \"Request failed with status code 400\"}"
        return JSON.parse(errorResponse);
      }
    });
    return response;
  }

  GetResetPasswordInfo(resetpasswordToken){
    let response = undefined;
    response = axios.get(API_URL + "GetResetPasswordInfo/" + resetpasswordToken)
    .catch((error) => {
      return error;
    });
    return response;
  }

  resetPassword(resetpasswordToken, mail, password, firstName="nofirstname", lastName="nolastname") {
    let response = undefined;
    response = axios.put(API_URL + "resetPassword/" + resetpasswordToken, {
      firstName,
      lastName,
      mail,
      password,
    })
    .catch((error) => {
      return error;
    });
    return response;
  }

  getRegistrationInfo(registrationToken) {
    const mURL = API_URL + "GetRegistrationInfo/" + registrationToken;
    return axios.get(mURL);
  }

  ConfirmRegistration(registrationToken) {
    const mURL = API_URL + "ConfirmRegistration/" + registrationToken;
    return axios.put(mURL);
  }

  ChangeOwnPassword(firstName, lastName, mail, password) {
    return axios.put(
      API_URL + "ChangeOwnPassword",
      {
        firstName,
        lastName,
        mail,
        password,
      },
      {
        headers: authHeader(),
      },
    );
  }

  ChangeUserPassword(id, firstName, lastName, mail, password) {
    return axios.put(
      API_URL + "ChangeUserPassword/" + id,
      {
        firstName,
        lastName,
        mail,
        password,
      },
      {
        headers: authHeader(),
      },
    );
  }

  info() {
    return axios.get(API_URL + "Info", { headers: authHeader() });
  }

  getRole() {
    const user = JSON.parse(localStorage.getItem("user") ?? "");
    switch (
      user[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ]
    ) {
      case "Admin":
        return roleType.admin;
      case "Manager":
        return roleType.manager;
      case "User":
        return roleType.user;
      case "Company Manager":
        return roleType.companymanager;
      case "Branch Manager":
        return roleType.branchmanager;
      case "Vehicle Manager":
        return roleType.vehiclemanager;
      default:
        return null;
    }
  }

  getProfile() {
    return axios.get(API_URL + "profile", { headers: authHeader() });
  }

  updateProfile(userProfile) {
    return axios.put(API_URL + "profile", userProfile, {
      headers: authHeader(),
    });
  }

  setLang(lang) {
    localStorage.setItem("lang", lang);
  }

  getLang() {
    return localStorage.getItem("lang");
  }

  ssoValidateUserToken(ssoToken) {
    let response = undefined;
    var authString = "Bearer " + ssoToken;
    //console.log("SsoValidateUserToken authString = " + authString);
    
    response = axios.get("/api/sso/validateusertoken",
    { 
      headers:{
        'Authorization': authString,
      }
    })
    .catch((error) => {
      return error;
    });
    
    return response;
  }

  

  ssoLogin() {
    let response = undefined;
    response = axios.get("/api/sso/login")
    .catch((error) => {
      return error;
    });
    
    return response;
  }
}

export default new AuthService();
