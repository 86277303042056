import { NOTIFY } from "../actions/types";

const initialState = {
  notification: undefined,
};

export default function (state = initialState, action) {
  const { type, title, content, autohide } = action;

  switch (type) {
    case NOTIFY:
      return {
        ...state,
        notification: {
          title: title,
          content: content,
          autohide: autohide,
        },
      };
    default:
      return state;
  }
}
