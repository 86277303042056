import { TOGGLE_VEHICLE } from "../actions/types";

const initialState = {
  vehicleToggleEnabled: true,
};

export default function (state = initialState, action) {
  const { type, enabled } = action;

  switch (type) {
    case TOGGLE_VEHICLE:
      return {
        ...state,
        vehicleToggleEnabled: enabled,
      };
    default:
      return state;
  }
}
