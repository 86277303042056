export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const SET_MANUFACTURER = "SET_MANUFACTURER";
export const SET_VEHICLE = "SET_VEHICLE";
export const TOGGLE_VEHICLE = "TOGGLE_VEHICLE";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const NOTIFY = "NOTIFY";
