export default function () {
  const persist = JSON.parse(localStorage.getItem("persist:root"));
  const userReducer = JSON.parse(persist.userReducer);
  const { token } = userReducer;

  if (token) {
    return { Authorization: "Bearer " + token, pragma: "no-cache" };
  } else {
    return {};
  }
}
