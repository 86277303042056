import { TOGGLE_SIDEBAR } from "../actions/types";

const initialState = {
  sidebarShow: "responsive",
};

export default function (state = initialState, action) {
  const { type, sidebarShow } = action;
  switch (type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarShow: sidebarShow,
      };
    default:
      return state;
  }
}
