import { SET_VEHICLE } from "../actions/types";

const initialState = {
  selectedManufacturerId: 0,
  selectedVehicleId: 0,
};

export default function (state = initialState, action) {
  const { type, manufacturerId, vehicleId } = action;

  switch (type) {
    case SET_VEHICLE:
      return {
        ...state,
        selectedManufacturerId: manufacturerId,
        selectedVehicleId: vehicleId,
      };
    default:
      return state;
  }
}
