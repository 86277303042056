import { combineReducers } from "redux";
import userReducer from "./userReducer";
import sidebarReducer from "./sidebarReducer";
import vehiclesReducer from "./vehiclesReducer";
import notifyReducer from "./notifyReducer";
import vehiclesSelectReducer from "./vehiclesSelectReducer";

export default combineReducers({
  userReducer: userReducer,
  sidebarReducer: sidebarReducer,
  vehiclesReducer: vehiclesReducer,
  notifyReducer: notifyReducer,
  vehiclesSelectReducer: vehiclesSelectReducer,
});
